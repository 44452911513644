export function signalEventName(name, type) {
    return `signal:${type}:${name}`;
}
export function signalConnectEvent(name) {
    return signalEventName(name, "connect");
}
export function signalValueEvent(name) {
    return signalEventName(name, "value");
}
export function signalVisibilityEvent(name, action) {
    return signalEventName(`${name}:${action}`, "visibility");
}
