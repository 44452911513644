import { BaseController } from "@stimulus-library/utilities";
export class MediaPlayerController extends BaseController {
    static targets = ["media"];
    async play(event) {
        event?.preventDefault();
        await this.mediaTarget.play();
    }
    pause(event) {
        event?.preventDefault();
        this.mediaTarget.pause();
    }
    restart(event) {
        event?.preventDefault();
        this.mediaTarget.currentTime = 0;
    }
    seek(event) {
        event?.preventDefault();
        this.mediaTarget.currentTime += 5;
    }
}
