import { BaseController } from "@stimulus-library/utilities";
import { useEventListener, useMutationObserver } from "@stimulus-library/mixins";
export class TableTruncateController extends BaseController {
    static targets = ["showMore"];
    static values = {
        limit: Number,
        truncated: Boolean,
        expanded: Boolean,
    };
    get _truncated() {
        return this.hasTruncatedValue ? this.truncatedValue : false;
    }
    set _truncated(value) {
        this.truncatedValue = value;
    }
    get _expanded() {
        return this.hasExpandedValue ? this.expandedValue : false;
    }
    set _expanded(value) {
        this.expandedValue = value;
    }
    get _tableBody() {
        return this.el.tBodies[0];
    }
    get _tableRows() {
        return Array.from(this._tableBody.rows);
    }
    get _limit() {
        return this.hasLimitValue ? this.limitValue : 20;
    }
    connect() {
        useMutationObserver(this, this._tableBody, this.mutate, { childList: true });
        requestAnimationFrame(() => {
            this.truncate();
            useEventListener(this, this.showMoreTarget, "click", this.expand);
        });
    }
    truncate(event) {
        event?.preventDefault();
        if (this._tableRows.length >= this._limit) {
            this._truncated = true;
            this._tableRows.slice(this._limit).forEach((el) => {
                if (el !== this.showMoreTarget) {
                    this._hideElement(el);
                }
            });
            this._showElement(this.showMoreTarget);
        }
        else {
            this._truncated = false;
            this._hideElement(this.showMoreTarget);
        }
    }
    expand(event) {
        this._expanded = true;
        event?.preventDefault();
        this._tableRows.slice(this._limit).forEach((el) => {
            if (el !== this.showMoreTarget) {
                this._showElement(el);
            }
        });
        this._hideElement(this.showMoreTarget);
    }
    mutate(_entries) {
        if (this._tableRows.length >= this._limit && !this._expanded) {
            this._reTruncate();
        }
    }
    _showElement(el) {
        el.style.display = "";
    }
    _hideElement(el) {
        el.style.display = "none";
    }
    _reTruncate() {
        this._tableRows.slice(0, this._limit).forEach((el) => {
            if (el !== this.showMoreTarget) {
                this._showElement(el);
            }
        });
        this._tableRows.slice(this._limit).forEach((el) => {
            if (el !== this.showMoreTarget) {
                this._hideElement(el);
            }
        });
        this._showElement(this.showMoreTarget);
    }
}
