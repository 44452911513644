import { BaseController } from "@stimulus-library/utilities";
import { useEventListener, useTimeout } from "@stimulus-library/mixins";
export class BackLinkController extends BaseController {
    static values = { timeout: Number, pages: Number };
    get _pages() {
        return this.hasPagesValue ? Math.abs(this.pagesValue) : 1;
    }
    get _timeout() {
        return this.hasTimeoutValue ? this.timeoutValue : 1500;
    }
    connect() {
        useEventListener(this, this.el, "click", this.goBack);
    }
    goBack(event) {
        event?.preventDefault();
        history.go(-this._pages);
        if (this.el.href) {
            useTimeout(this, this.fallback, this._timeout);
        }
    }
    fallback() {
        window.location.href = this.el.href;
    }
}
