import { BaseController, EventBus } from "@stimulus-library/utilities";
import { extractExpressions, extractPredicates } from "./expressions";
import { signalConnectEvent, signalValueEvent } from "./events";
import { useEventBus } from "@stimulus-library/mixins";
export class SignalBaseController extends BaseController {
    static values = {
        name: String,
    };
    get predicateString() {
        return "";
    }
    get _predicates() {
        return extractPredicates(this.predicateString);
    }
    get _expressions() {
        return extractExpressions(this.predicateString);
    }
    connect() {
        EventBus.emit(signalConnectEvent(this.nameValue));
        useEventBus(this, signalValueEvent(this.nameValue), this._onSignal);
    }
    predicatesMatch(value) {
        if (this.predicateString.includes("||")) {
            return this._predicates.some(predicate => predicate(value));
        }
        else {
            return this._predicates.every(predicate => predicate(value));
        }
    }
}
