import { BaseController } from "@stimulus-library/utilities";
export class CheckboxDisableInputsController extends BaseController {
    static targets = ["disabler", "disable"];
    static values = {
        clear: Boolean,
    };
    connect() {
        this.toggle();
    }
    toggle() {
        if (this.hasDisablerTarget && this.disablerTarget.checked) {
            this.disable();
        }
        else {
            this.enable();
        }
    }
    disable() {
        const shouldClear = this.hasClearValue && this.clearValue;
        this.disableTargets.forEach((el, _) => {
            if (shouldClear) {
                el.value = "";
            }
            el.disabled = true;
        });
    }
    enable() {
        this.disableTargets.forEach((el, _) => {
            el.disabled = false;
        });
    }
}
