import { BaseController } from "@stimulus-library/utilities";
export class FallbackImageController extends BaseController {
    static values = { placeholder: String };
    static classes = ["success", "fail"];
    initialize() {
        this._hasLoadedSuccessfully = this._hasLoadedSuccessfully.bind(this);
        this._success = this._success.bind(this);
        this._fail = this._fail.bind(this);
    }
    connect() {
        const element = this.el;
        element.onerror = this._fail;
        if (element.complete && !this._hasLoadedSuccessfully()) {
            this._fail();
        }
        else {
            this._success();
        }
    }
    disconnect() {
        this.removeSuccessClasses();
        this.removeFailClasses();
    }
    _success() {
        this.addSuccessClasses();
    }
    _fail() {
        const element = this.el;
        this.addFailClasses();
        if (this.hasPlaceholderValue && element.src !== this.placeholderValue) {
            this.dispatchEvent(element, this.eventName("placeholder"));
            element.src = this.placeholderValue;
            element.onerror = this._fail;
        }
        else {
            this.dispatchEvent(element, this.eventName("fail"));
            element.style.display = "none";
        }
    }
    _hasLoadedSuccessfully() {
        const element = this.el;
        return element.naturalHeight > 0 && element.naturalWidth > 0;
    }
}
