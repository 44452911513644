import { BaseController } from "@stimulus-library/utilities";
import { useDirtyFormTracking } from "@stimulus-library/mixins";
export class DetectDirtyFormController extends BaseController {
    _restore;
    connect() {
        const { restore } = useDirtyFormTracking(this, this.el);
        this._restore = restore;
    }
    restore(event) {
        event?.preventDefault();
        if (this._restore) {
            this._restore();
        }
    }
}
