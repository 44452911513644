import { BaseController, scrollToElement } from "@stimulus-library/utilities";
import { useEventListener } from "@stimulus-library/mixins";
export class LightboxImageController extends BaseController {
    static values = {
        src: String,
        srcSet: String,
        sizes: String,
    };
    static classes = ["modal", "image"];
    _dialog = null;
    get _src() {
        return this.hasSrcValue ? this.srcValue : this.el.src;
    }
    get _srcSet() {
        return this.hasSrcSetValue ? this.srcSetValue : this.el.srcset;
    }
    get _sizes() {
        return this.hasSizesValue ? this.sizesValue : this.el.sizes;
    }
    get _modalClassName() {
        return this.hasModalClass ? this.modalClass : "image-lightbox-dialog";
    }
    get _imageClassName() {
        return this.hasImageClass ? this.imageClass : "image-lightbox-image";
    }
    initialize() {
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }
    connect() {
    }
    disconnect() {
        this.close();
    }
    open() {
        const element = this.el;
        if (this._dialog) {
            return;
        }
        this._dialog = document.createElement("dialog");
        const image = document.createElement("img");
        image.className = this._imageClassName;
        image.src = this._src;
        image.srcset = this._srcSet;
        image.sizes = this._sizes;
        this._dialog.appendChild(image);
        element.insertAdjacentElement("afterend", this._dialog);
        this._dialog.className = this._modalClassName;
        this._dialog.showModal();
        scrollToElement(this._dialog, { behavior: "smooth", block: "end" }).catch(() => this._dialog.scrollIntoView(false));
        useEventListener(this, this._dialog, "click", this.close);
        useEventListener(this, this._dialog, "cancel", this.close);
        useEventListener(this, this._dialog, "close", this.close);
    }
    close() {
        if (this._dialog) {
            // @ts-ignore Experimental API
            this._dialog.close();
            this._dialog.remove();
            this._dialog = null;
            scrollToElement(this.el, { behavior: "smooth", block: "end" }).catch(() => this.el.scrollIntoView(false));
        }
    }
}
