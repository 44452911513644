import { BaseController } from "@stimulus-library/utilities";
export class PasswordPeekController extends BaseController {
    static targets = ["password"];
    peak(event) {
        event?.preventDefault();
        this.passwordTarget.type = "text";
    }
    hide(event) {
        event?.preventDefault();
        this.passwordTarget.type = "password";
    }
    toggle(event) {
        event?.preventDefault();
        if (this.passwordTarget.type === "password") {
            this.peak();
        }
        else {
            this.hide();
        }
    }
}
