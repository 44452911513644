import { BaseController } from "@stimulus-library/utilities";
import { useDirtyInputTracking } from "@stimulus-library/mixins";
export class DetectDirtyController extends BaseController {
    _restore;
    connect() {
        const element = this.el;
        const { restore } = useDirtyInputTracking(this, element);
        this._restore = restore;
    }
    restore(event) {
        event?.preventDefault();
        if (this._restore) {
            this._restore();
        }
    }
}
